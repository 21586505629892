<template>
  <b-card title="Assinar documentos PDF">
    <b-form ref="form">
      <b-row>
        <b-col md="5">
          <b-form-group label="Anexar documento">
            <b-form-file
              id="file-input"
              ref="file-input"
              accept=".pdf"
              v-model="file"
              placeholder="Escolha um arquivo ou arraste-o aqui..."
              drop-placeholder="Solte o arquivo aqui..."
            ></b-form-file>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Modelo de Assinatura">
            <b-form-select
              v-model="modeloAssinatura"
              :options="modeloAssinaturaOptions"
              required
              placeholder="Selecione um modelo"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox
            id="assinarTodasPaginas"
            v-model="assinarTodasPaginas"
            name="assinarTodasPaginas"
            :value="true"
            :unchecked-value="false"
          >
            Assinar todas as páginas
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="auto">
          <b-button
            :disabled="!file || isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px; width: 170px"
            @click.prevent="authESalve"
          >
            {{ !isBusy ? 'Enviar e Assinar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            style="margin-top: 25px; width: 170px"
            @click="limpar"
          >
            Limpar
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- Modal de Senha -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import Ripple from 'vue-ripple-directive'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import useJwt from '@/auth/jwt/useJwt'
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'

  export default {
    components: {
      AutenticaSenha,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isBusy: false,
        modeloAssinaturaOptions: [
          { value: 'geral', text: 'Geral' },
          { value: 'contrato', text: 'Contrato' },
        ],
        modeloAssinatura: null,
        mode: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        assinarTodasPaginas: false,
        file: null,
      }
    },
    watch: {
      file(newVal, _) {
        if (!newVal.name.includes(`.pdf`)) {
          this.$message.error('Formato de documento inválido. Apenas arquivos PDF são aceitos.')
          this.$refs['file-input'].reset()
        }
      },
    },
    methods: {
      authESalve() {
        this.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.assinarDocumentos()
          }
        }
      },
      limpar() {
        this.$refs['file-input'].reset()
      },
      assinarDocumentos() {
        this.isBusy = true

        let boolAssinaTodasAsPaginas = this.assinarTodasPaginas ? 1 : 0
        let urlDoUsuario = window.location.origin + '/AssinaturaDocumento'
        const formData = new FormData()
        formData.append('nome', 'assinarDocumento')
        formData.append('institutoId', this.userData.institutoSelecionado)
        formData.append('userId', this.userData.id)
        formData.append('urlDoUsuario', urlDoUsuario)
        formData.append('assinarTodasPaginas', boolAssinaTodasAsPaginas)
        formData.append('modeloAssinatura', this.modeloAssinatura)
        formData.append('file', this.file)

        useJwt
          .postDocumento(`assinatura-documentos/AssinaDocumento`, () => {}, formData)
          .then((response) => {
            this.$message.success('Documento assinado com sucesso')
            const s3Key = response.data.s3Key
            const fileName = response.data.fileName
            this.downloadArquivo(s3Key, fileName)
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao assinar documento')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      async downloadArquivo(s3Key, fileName) {
        await downloadFileAWS(s3Key, fileName).catch((error) => {
          console.error(error)
          this.$message.error('Erro ao realizar o download!')
        })
      },
    },
  }
</script>
